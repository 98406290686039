import React, { useEffect, useRef, useState, useContext } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { node, oneOf, string, number } from "prop-types"
import { ThemeContext } from "styled-components"

import ContextAllImages from "../../../context/allImages.context"
import useWindowSize from "../../../hooks/useWindowSize"
import { isIOS } from "../../../helpers/platform"
import { BgBlockStyle, BgBlockBgStyle, BgBlockContentStyle } from "./BgBlock.style"

const BgBlock = props => {
  const { children, type, theme, depth, color, start, end, heightPercentToReduce, className } = props
  const rand = Math.ceil(Math.random() * 1000000000000)
  const classNameID = `c-bg-block`
  const classNameRand = `${classNameID}-${rand}`
  const { getImageSrc } = useContext(ContextAllImages)
  const themeContext = useContext(ThemeContext)
  const windowSize = useWindowSize()
  const [update, setUpdate] = useState(0)
  const [data, setData] = useState({
    introData: { y: 0, h: 0 },
    topData: { y: 0, h: 0 },
    middleData: { y: 0, h: 0, calcH: 0 },
    downData: { y: 0, h: 0 },
    extraData: { x: 0, w: 0 },
    finalH: 0,
    finalW: 0,
  })
  const [theColor, setTheColor] = useState(theme === "dark" ? `${themeContext.vars.steelGray}` : `${themeContext.vars.white}`)
  const baseTrigger = useRef(null)
  const content = useRef(null)
  const tlAnim = useRef(null)
  const realW = 1920
  const introMask = {
    w: 1920,
    h: 650,
    // src: '/assets/images/mascaras/1920-header-mascara-intro.jpg',
    src: getImageSrc("1920-header-mascara-intro.jpg"),
  }
  const topMask = {
    w: 1920,
    h: 650,
    // src: '/assets/images/mascaras/1920-header-mascara-alta.jpg',
    src: getImageSrc("1920-header-mascara-alta.jpg"),
  }
  const downMask = {
    w: 1920,
    h: 650,
    // src: '/assets/images/mascaras/1920-header-mascara-baja.jpg',
    src: getImageSrc("1920-header-mascara-baja.jpg"),
  }
  const textureData = {
    w: 960,
    h: 960,
    // src: '/assets/images/texturas/textura-tile-mapa.png',
    src: getImageSrc("textura-tile-mapa.png"),
  }
  const noiseData = {
    w: 200,
    h: 200,
    // src: '/assets/images/texturas/textura-tile-noise.png',
    src: getImageSrc("textura-tile-noise.png"),
  }
  const noiseDetail = {
    w: 100,
    h: 100,
    // src: '/assets/images/texturas/textura-noise-detail.png',
    src: getImageSrc("textura-noise-detail.png"),
  }
  const mobileW = 768
  const mobileScale = mobileW / topMask.w
  const mobileSize = { w: mobileW, h: topMask.h * mobileScale }

  const updateSize = () => {
    const isMobile = window.innerWidth < mobileW
    const windowW = isMobile ? mobileSize.w : window.innerWidth
    const downH = isMobile ? mobileSize.h : (downMask.h * windowW) / realW
    let heightToReduce = 0
    if (type === "cards") {
      heightToReduce = (300 * windowW) / realW
    } else if (heightPercentToReduce !== 0) {
      heightToReduce = (((heightPercentToReduce * (isMobile ? mobileSize.h : topMask.h)) / 100) * windowW) / realW
    }
    let cH = content.current.offsetHeight - heightToReduce
    if (cH < 10) cH = content.current.offsetHeight
    let calcH = depth !== 0 ? cH * depth - downH * depth + (isMobile ? 150 * depth : 0) : 0
    calcH = type === "hideBottom" ? calcH + downH * depth : calcH
    if (isIOS()) calcH = 0
    // const contentH = content.current.offsetHeight
    const contentH = cH + calcH
    // calcH = 0
    // contentH = cH
    const theData = {
      introData: { y: 0, h: 0 },
      topData: { y: 0, h: 0 },
      middleData: { y: 0, h: 0, calcH: 0 },
      downData: { y: 0, h: 0 },
      extraData: { x: 0, w: windowW },
      finalH: 0,
      finalW: windowW,
    }
    if (type === "intro") {
      const introH = isMobile ? mobileSize.h : (introMask.h * windowW) / realW
      theData.finalH = introH + contentH + downH - 5
      theData.introData = { y: 0, h: introH }
      theData.middleData = { y: introH - 2, h: contentH, calcH }
      theData.downData = { y: introH + contentH - 3, h: downH }
    } else {
      const topH = isMobile ? mobileSize.h : (topMask.h * windowW) / realW
      theData.topData = { y: 0, h: topH }
      if (type === "hideTop") {
        theData.finalH = Math.ceil(contentH + downH - 2)
        theData.middleData = { y: 0, h: contentH, calcH }
        theData.downData = { y: contentH - 2, h: downH + 3 }
      } else if (type === "hideBottom") {
        theData.finalH = Math.ceil(topH + contentH - 2)
        theData.downData = { y: 0, h: 0 }
        theData.middleData = { y: topH - 2, h: contentH, calcH }
      } else {
        theData.finalH = Math.ceil(topH + contentH + downH - 5)
        theData.middleData = { y: topH - 2, h: contentH, calcH }
        theData.downData = { y: topH + contentH - 3, h: downH }
      }
    }
    setData(theData)
  }

  const setAnimations = () => {
    const contentH = content.current.offsetHeight
    gsap.utils.toArray(`.${classNameRand} .bg-parallax`).forEach(layer => {
      const theDepth = Number(layer.dataset.depth)
      // const movement = -(layer.offsetHeight * depth)
      const movement = -(contentH * theDepth)
      tlAnim.current.fromTo(layer, { y: 0 }, { y: movement, ease: "none" }, 0)
    })
  }

  const initAnimations = () => {
    // console.log('set initial animations');
    if (!isIOS()) {
      tlAnim.current = gsap.timeline({
        scrollTrigger: {
          trigger: baseTrigger.current,
          start,
          end,
          scrub: 0.5,
          toggleClass: "is-active",
          // markers: true,
        },
      })
      setAnimations()
    }
  }

  useEffect(() => {
    if (depth !== 0) {
      // console.log('initial', windowSize, type, depth);
      initAnimations()
    }
    if (color) setTheColor(color)
    return () => {
      if (tlAnim.current) {
        tlAnim.current.clear()
        tlAnim.current.kill()
        tlAnim.current = null
      }
    }
  }, [])

  useEffect(() => {
    let timer = 0
    if (windowSize.width !== 0) {
      // console.log('toUpdate', windowSize.width, type, depth);
      // if (!tlAnim.current) initAnimations()
      if (tlAnim.current) tlAnim.current.clear()
      clearTimeout(timer)
      timer = setTimeout(() => {
        updateSize()
        setUpdate(update + 1)
      }, 100)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [windowSize.width, type, depth, heightPercentToReduce])

  useEffect(() => {
    let timer = 0
    if (windowSize.width !== 0 && tlAnim.current) {
      // console.log('update', update, windowSize.width);
      if (!tlAnim.current) initAnimations()
      else setAnimations()
      timer = setTimeout(() => {
        ScrollTrigger.refresh()
      }, 100)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [update])

  return (
    <BgBlockStyle className={`${classNameID} ${classNameRand} ${className}`}>
      <BgBlockBgStyle type={type} posY={type === "hideBottom" ? data.middleData.calcH : data.middleData.calcH / 2} style={{ width: data.finalW }}>
        <div
          style={{
            height: data.finalH,
            width: data.finalW,
            position: "absolute",
            top: 0,
            // border: '1px solid red',
            // zIndex: 1,
          }}
          ref={baseTrigger}
          className={`${classNameID}__trigger`}
        />
        <div className="bg-parallax" data-depth={depth}>
          <svg width={data.finalW} height={data.finalH} viewBox={`0 0 ${data.finalW} ${data.finalH}`}>
            <mask id={`home-intro-mask-${rand}`}>
              {type !== "hideTop" && <>{type === "intro" ? <image x={data.extraData.x} y={data.introData.y} xlinkHref={introMask.src} href={introMask.src} width={data.finalW} height={data.introData.h} /> : <image x={data.extraData.x} y={data.topData.y} xlinkHref={topMask.src} href={topMask.src} width={data.finalW} height={data.topData.h} />}</>}
              <rect x={data.extraData.x} y={data.middleData.y} width={data.finalW} height={data.middleData.h} fill={themeContext.vars.white} />
              {type !== "hideBottom" && <image x={data.extraData.x} y={data.downData.y} xlinkHref={downMask.src} href={downMask.src} width={data.finalW} height={data.downData.h} />}
            </mask>
            <defs>
              {type !== "detail" ? (
                <>
                  <pattern id={`home-intro-noise-${rand}`} patternUnits="userSpaceOnUse" width={noiseData.w} height={noiseData.h}>
                    <image xlinkHref={noiseData.src} href={noiseData.src} x="0" y="0" width={noiseData.w} height={noiseData.h} />
                  </pattern>
                  <pattern id={`home-intro-map-${rand}`} patternUnits="userSpaceOnUse" width={textureData.w} height={textureData.h}>
                    <image xlinkHref={textureData.src} href={textureData.src} x="0" y="0" width={textureData.w} height={textureData.h} />
                  </pattern>
                </>
              ) : (
                <pattern id={`home-detail-noise-${rand}`} patternUnits="userSpaceOnUse" width={noiseDetail.w} height={noiseDetail.h}>
                  <image xlinkHref={noiseDetail.src} href={noiseDetail.src} x="0" y="0" width={noiseDetail.w} height={noiseDetail.h} />
                </pattern>
              )}
            </defs>
            <g mask={`url(#home-intro-mask-${rand})`}>
              <rect x={data.extraData.x} y={data.topData.y} width={data.finalW} height={data.finalH} fill={theColor} />
              {type !== "detail" ? (
                <>
                  <rect x={data.extraData.x} y={data.topData.y} width={data.finalW} height={data.finalH} fill={`url(#home-intro-noise-${rand})`} style={{ position: "relative" }} />
                  <rect x={data.extraData.x} y={data.topData.y} width={data.finalW} height={data.finalH} fill={`url(#home-intro-map-${rand})`} style={{ position: "relative" }} />
                </>
              ) : (
                <rect x={data.extraData.x} y={data.topData.y} width={data.finalW} height={data.finalH} fill={`url(#home-detail-noise-${rand})`} style={{ position: "relative" }} />
              )}
            </g>
          </svg>
        </div>
      </BgBlockBgStyle>
      {children ? <BgBlockContentStyle ref={content}>{children}</BgBlockContentStyle> : <div />}
    </BgBlockStyle>
  )
}

BgBlock.propTypes = {
  children: node,
  type: oneOf(["default", "intro", "hideTop", "hideBottom", "footer", "cards", "detail"]),
  theme: oneOf(["dark", "light"]),
  depth: number,
  color: string,
  start: string,
  end: string,
  heightPercentToReduce: number,
  className: string,
}

BgBlock.defaultProps = {
  children: <div style={{ height: 100, width: "100%" }} />,
  type: "default",
  theme: "light",
  depth: 0.5,
  color: null,
  start: "15% 90%",
  end: "85% 10%",
  heightPercentToReduce: 0,
  className: "",
}

export default React.memo(BgBlock)
