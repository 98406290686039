import styled, { css } from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const BgBlockStyle = styled.div`
  position: relative;
  width: 100%;
`

export const BgBlockBgStyle = styled.div`
  left: 50%;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;

  @media ${device.sm} {
    left: 0;
    transform: none;
    width: 100% !important;
  }

  svg {
    position: relative;
  }
  .bg-parallax {
    display: flex;
    justify-content: center;
    position: relative;
  }

  ${props =>
    (props.type === "default" || props.type === "cards" || props.type === "intro" || props.type === "detail") &&
    css`
      top: 50%;
      transform: translate(-50%, -50%);
      svg {
        transform: translateY(${props.posY}px);
      }
      @media ${device.sm} {
        transform: translateY(-50%);
      }
    `}

  ${props =>
    props.type === "hideBottom" &&
    css`
      bottom: 0;
      transform: translateX(-50%);
      svg {
        transform: translateY(${props.posY}px);
      }
      @media ${device.sm} {
        left: 50%;
        transform: translateX(-50%);
      }
    `}
`

export const BgBlockContentStyle = styled.div`
  /* border: 1px solid red; */
  min-height: 10px;
  position: relative;
`
