import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

export const ThankYouPageStyle = styled.div`
  min-height: 100vh;
  padding-bottom: 40vw;

  @media ${device.sm} {
    min-height: calc(100vh - 145px);
    padding-bottom: 25vw;
  }
  @media ${device.md} {
    min-height: calc(100vh - 80px);
    padding-bottom: 150px;
  }
  @media ${device.xl} {
    min-height: calc(100vh - 111px);
    padding-bottom: 200px;
  }
`

export const ContainerStories = styled.div`
  padding-top: 40vw;
  width: 100%;

  @media ${device.sm} {
    padding-top: 25vw;
  }
  @media ${device.md} {
    padding-top: 150px;
  }
  @media ${device.xl} {
    padding-top: 200px;
  }
`

export const ContainerInfo = styled.div`
  text-align: center;
`

export const PageTitle = styled.h1`
  font-family: ${props => props.theme.vars.avenirNext};
  color: ${props => props.theme.vars.white};
  font-weight: ${props => props.theme.vars.fontBlack};
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 20px;

  @media ${device.sm} {
    font-size: 88px;
    margin-bottom: 30px;
  }
  @media ${device.lg} {
    font-size: 100px;
  }
  @media ${device.xl} {
    font-size: 140px;
    margin-bottom: 50px;
  }
`

export const TextGolden = styled.h5`
  color: ${props => props.theme.vars.goldenTainoi};
  font-family: ${props => props.theme.vars.avenirNext};
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 20px;

  @media ${device.sm} {
    font-size: 18px;
  }
  @media ${device.xl} {
    font-size: 24px;
  }
`

export const TextDescription = styled.p`
  color: ${props => props.theme.vars.white};
  font-family: ${props => props.theme.vars.openSans};
  text-align: center;
  font-size: 12px;
  line-height: 1.8;
  margin-bottom: 40px;
  padding: 0 10px;

  @media ${device.sm} {
    font-size: 16px;
    padding: 0 20px;
  }
  @media ${device.xl} {
    font-size: 20px;
    padding: 0 300px;
    margin-bottom: 50px;
  }
`

export const TextHashtag = styled.h5`
  color: ${props => props.theme.vars.white};
  font-family: ${props => props.theme.vars.avenirNext};
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 40px;

  @media ${device.sm} {
    font-size: 16px;
    margin-bottom: 50px;
  }
  @media ${device.xl} {
    font-size: 20px;
    margin-bottom: 60px;
  }
`

export const ContainerButtom = styled.div`
  .Button-Mas {
    background-color: ${props => props.theme.vars.goldenTainoi};
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 12px;
    font-weight: ${props => props.theme.vars.fontBold};
    color: ${props => props.theme.vars.black};
    border: none;
    width: 220px;
    letter-spacing: 2px;
    font-weight: bold;

    @media ${device.sm} {
      width: 280px;
      font-size: 15px;
    }
    @media ${device.md} {
      width: 280px;
    }
    @media ${device.xl} {
      width: 300px;
      font-size: 16px;
    }
  }
  .Button-Mas:hover {
    cursor: pointer;
    background-color: ${props => props.theme.vars.outerSpace};
    color: ${props => props.theme.vars.white};
  }
`
