import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { Link } from "gatsby"

import ButtonText from "../../elements/buttonText/ButtonText"
import BgBlock from "../../elements/bgBlock/BgBlock"
import { ThankYouPageStyle, ContainerStories, ContainerInfo, PageTitle, TextGolden, TextDescription, TextHashtag, ContainerButtom } from "./thankYouPage.styles"

const ThankYouPage = () => {
  return (
    <ThankYouPageStyle>
      <BgBlock type="hideTop" theme="dark" depth={0} heightPercentToReduce={50}>
        <ContainerStories>
          <Container>
            <Row>
              <Col xs={4} sm={8} md={10} lg={8} xl={12} offset={{ xs: 0, md: 1, lg: 2, xl: 0 }}>
                <ContainerInfo>
                  <PageTitle>JeepStories</PageTitle>
                  <TextGolden>¡Gracias por compartir tu historia!</TextGolden>
                  <TextDescription>¡Muy pronto el mundo podrá conocerla! Tu historia será revisada y pasará por un proceso de evaluación; si cumple con los requisitos, será publicada en wall de Jeepstories.</TextDescription>
                  <TextHashtag>#JEEPSOLOHAYUNO</TextHashtag>
                  <ContainerButtom>
                    <Link to="/">
                      <ButtonText className="Button-Mas" classTag="tag-formulario-enviado-conoce">
                        CONOCE MÁS HISTORIAS
                      </ButtonText>
                    </Link>
                  </ContainerButtom>
                </ContainerInfo>
              </Col>
            </Row>
          </Container>
        </ContainerStories>
      </BgBlock>
    </ThankYouPageStyle>
  )
}

export default ThankYouPage
